.login-page-wrapper{
  height: calc(100% - 261px);
  min-height: 500px;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  background-color: white;
}


.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.background-image{
background-size: cover;
height: 100%;
}

.login-page-container>input {
    width: 235px;
    height: 25px;
    margin: 5px 0px;
    padding: 15px 10px;
    border-radius: 5px;
    color: black;
    background-color: rgba(128, 128, 128, 0.139);
}

.login-form-button {
    width: 250px;
    height: 35px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ff5353;
}

.demo-user-button {
    width: 250px;
    height: 35px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
}

.new-platepal-container {
    margin-top: 10px;
    font-size: 12px;
}

.new-platepal-sign-up {
    color: #0073bb;
    text-decoration: none;
}

.login-form-errors {
    color: red;
}
