.activity-delete-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 200px;
    padding: 20px;
}

.activity-delete-form {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.activity-delete-form-title {
    margin: 0;
    display: flex;
    align-items: center;
}


.activity-delete-form-delete-button {
    background-color: #ff5353;
    border: none;
    padding: 6px 12px;
    color: white;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #f0f0f5;
    transition-property: background-color;
    transition-duration: 0.5s;
    cursor: pointer;
}

.activity-delete-form-delete-button:hover {
    background-color: #d03e3e;
}

.activity-delete-form-cancel-delete-button {
    background-color: white;
    border: none;
    padding: 6px 12px;
    color: #494950;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #f0f0f5;
    transition-property: background-color;
    transition-duration: 0.5s;
    cursor: pointer;
}

.activity-delete-form-cancel-delete-button:hover {
    background-color: #f0f0f5;
}
