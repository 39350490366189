/* TODO Add site wide styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: MaisonNeue,Segoe UI,Helvetica Neue,-apple-system,system-ui,BlinkMacSystemFont,Roboto,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

button {
    background-color: #ff5353;
}

body {
    background-color: #f7f7fa;
    height: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

html {
    height: 100%;
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
