/* ----------------  */
/* PROFILEBUTTON.JS  */
/* ----------------  */

/* border and cushion for options that pop up */



.training-dropdown {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.244);
  border-top: none;
  text-align: left;
  padding: 10px 15px 10px 15px;
  left: 340px;
  margin-top: 27px;
  background-color: white;
}



.profile-dropdown>hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* hide menu when not clicked on */
/* .hidden {
  display: none;
} */

/* styling menu buttons, rounded with spacing */

.nav-right-activity-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  position: relative;

}

.show-border {
  border-right: 1px solid rgba(0, 0, 0, 0.244);
  border-left: 1px solid rgba(0, 0, 0, 0.244);
  border-bottom: 1px solid white;
}

.activity-button {
  /* height: 25px;
  width: 25px;
  border: none; */
  color: #ff5353;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.activity-dropdown {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.244);
  text-align: left;
  right: -1px;
  top: 59px;
  padding-inline-start: 0;
  background-color: white;
  z-index: -1;
  width: 200px;
}

.nav-right-profile-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  position: relative;
}

.profile-dropdown {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.244);
  text-align: left;
  right: -1px;
  top: 59px;
  padding-inline-start: 0;
  background-color: white;
  z-index: -1;
  width: 164px;
}

.profile-button {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  border: 1px solid black;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
}

.profile-button-image{
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.user-drop-down-name {
  margin-bottom: 10px;
}

.manage-user-items {
  color: #0073bb;
  text-decoration: none;
}

.user-drop-down-menu {
  display: flex;
  flex-direction: column;
}

.user-drop-down-menu-button {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition-duration: .5s;
  transition-property: background-color;
  text-decoration: none;
  color: #6d6d78;
}

.user-drop-down-menu-button:hover {
  background-color: rgb(240, 240, 240);
}


.button-logout {
  color: white;
  font-size: 14px;
  height: 32.5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.login-signup-css {
  display: flex;
  flex-direction: row;
}

.login-button,
.signup-button {
  width: 80px;
  height: 35px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.login-button {
  margin-right: 15px;
  background-color: white;
  border-color: gray;
}

.signup-button {
  color: white;
  margin-right: 20px;
}

/* -------- */
/* INDEX.JS */
/* -------- */

/* keep navbar outer parent fixed for scrolling */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  /* border-bottom: 2px solid grey; */
  background-color: white;
  z-index: 1;
  border-bottom: solid 1px rgba(128, 128, 128, 0.171);
  box-sizing: border-box;
}

/* ul parent containing the logo & menu button */
.navbar-content {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 40px;
  align-items: center;
  height: 60px;
  box-sizing: border-box;

  max-width: 1500px;
}

/* remove bulletpoint from ul (& its li children) */
ul {
  list-style-type: none;
}

/* position two items on  */
.nav-right-loggedin-user {
  display: flex;
  align-items: center;
}

.nav-right-add-business {
  color: black;
  margin-right: 20px;
  text-decoration: none;
}

.nav-right-add-business:hover {
  color: white;
  background-color: lightslategray;
  border-radius: 5px;
  height: 20px;
  padding: 3px 0px;
}

/* resize logo to be slightly smaller than full parent size */
.logo-image {
  min-width: 133px;
}

.nav-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
