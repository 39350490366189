.modal-icon{
    cursor: pointer;
}

.comments-modal-cancel-delete-button {
    background-color: white;
    border: none;
    padding: 6px 12px;
    color: #494950;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #f0f0f5;
    transition-property: background-color;
    transition-duration: 0.5s;
    cursor: pointer;
}

.comments-modal-cancel-delete-button:hover {
    background-color: #f0f0f5;
}
