.profile-image-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;


    margin: auto;
    border-radius: 20px;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
}
