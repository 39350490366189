.sign-up-page-wrapper {
  height: calc(100% - 261px);
  display: flex;
  background-size: cover;
  min-height: 500px;
}


.sign-up-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 349px;
  margin: auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, .95) 20%, rgba(0, 0, 0, 0.6) 80%);
  padding: 20px 50px;
  height: 100%;
  color: white;
}

.sign-up-page-container>input {
  width: 100%;
  height: 25px;
  margin: 5px 0px;
  padding: 0px 5px;
  color: black;
}

.sign-up-page-names{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

}

.sign-up-page-names>input {
  width: 100%;
  height: 25px;
  margin: 5px 0px;
  padding: 0px 5px;
  color: black;
}

.sign-up-page-names>select {
  width: 124.5px;
  height: 29px;
  padding: 0px;
  color: black;
}

.sign-up-page-names-labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10px;
}

.signup-form-button {
  width: 250px;
  height: 35px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff5353;
}

.new-platepal-log-in {
  color: #56beffe8;
  text-decoration: underline;
}

.signup-form-errors {
  color: #ff5353;
}

.signup-form-date-errors {
  color: #ff5353;
  display: grid;
  grid-template-columns: 1fr 1fr
}
