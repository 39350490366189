.activities-manage-container-background {
    background-color: white;
    min-height: calc((100% - 80px) * .8);
    padding: 20px;
    box-sizing: border-box;
    overflow-x: auto;
}

.activities-manage-container {
    width: 1216px;
    margin: auto;
    overflow-y: auto;

}

.activities-manage-headers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
}
