.comment-modal-container {
    display: flex;
    flex-direction: column;
    max-width: 804px;
}

.comment-modal {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.comment-modal-activity-info-container{
    display: flex;
    padding: 10px 12px 10px 24px;
    justify-content: flex-start;
    height: 50px;
    align-items: center;
}

.comment-modal-activity-info-container i {
    font-size: 32px;
}

.comment-modal-owner-image img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.comment-modal-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.comment-modal-tabs-container{
    height: 50px;
    padding-left: 24px;
    background-color: #f0f0f5;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(223, 223, 232);
    display: flex;
    align-items: center;
}

.comment-modal-kudos-tab{
    padding: 12px 16px;
    border-top: solid 3px #f0f0f5;
    font-size: 14px;
    cursor: pointer;

}

.comment-modal-kudos-tab-active{
    padding: 12px 16px;
    border-top: solid 3px #ff5353;
    background-color: white;
    border-bottom: solid 2px white;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    top: 1px;
}

.comment-modal-comments-tab{
    padding: 12px 16px;
    border-top: solid 3px #f0f0f5;
    font-size: 14px;
    cursor: pointer;
}

.comment-modal-comments-tab-active{
    padding: 12px 16px;
    border-top: solid 3px #ff5353;
    background-color: white;
    border-bottom: solid 2px white;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    top: 1px;
}

.comment-modal-comments-container{
    box-sizing: border-box;
    border-bottom: 1px solid rgb(223, 223, 232);
    padding: 24px 24px 0 24px;
    overflow-y: scroll;
}

.comment-modal-kudos-container{
    box-sizing: border-box;
    border-bottom: 1px solid rgb(223, 223, 232);
    padding: 24px 24px 0 24px;
    overflow-y: scroll;
}

.comment-modal-comments-submit-container{
    height: 93px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;

}

.comment-modal-kudos-submit-container {
    height: 68px;
    padding: 12px 24px;
    box-sizing: border-box;
    background-color: #f0f0f5;
    border-radius: 5px;
}

.comment-modal-kudos-submit-button{
    height: 100%;
    padding: 12px 24px;
    box-sizing: border-box;
    text-align: center;
    border: solid 1px #f0f0f5;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    transition-property: background-color;
    transition-duration: 0.5s;
    cursor: pointer;
}

.comment-modal-kudos-submit-button:hover {
    background-color: #d03e3e;
}

.comment-modal-comments-submit-container-profile-icon{
    width: 46px;
    height: 56px;
    box-sizing: border-box;
    padding-right: 12px;
}

.comment-modal-comments-submit-container-profile-icon img{
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.comment-modal-comments-submit-input {
    border: none;
    width: 100%;
    display: flex;
    align-items: flex-start;
    resize: none;
}

.comment-modal-comments-submit-input:focus {
    background-color: #f7f7fa;
}

.comment-modal-comments-submit-button-container {
    width: 60px;
    box-sizing: border-box;

    background-color: white;
    border: none;
}

.comment-modal-comments-submit-button {
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px ;
    width: 50px;
    border: none;
    color: #ff5353;
    background-color: white;
    font-weight: 600;
    transition-property: background-color;
    transition-duration: .5s;
}

.comment-modal-comments-submit-button:hover {
    background-color: #ffeded;
}

.placeholder::placeholder{
    color: red;
}
