.users-search-item{
  display: grid;
  margin: auto;
  padding: 16px 0;
  height: 110px;
  box-sizing: border-box;
}



.gray {
  background-color: #f7f7fa;
}

.users-search-item-owner-container{
    padding: 0px 10px;
    display: grid;
    font-size: 17px;
    grid-template-columns: 40px 1fr;
}

.users-search-item-owner-container img{
  border-radius: 50%;
}

.users-search-item-owner-information{
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.users-search-item-owner-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.users-search-item-owner-activities-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-template-columns: 25% 56% 50%;

}
.users-search-item-type-title-container{
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    margin-top: 5px;
}

.users-search-item-title{
    line-height: 28px;
    font-size: 22px;
    font-weight: 700;
}

.users-search-follow-submit-container {
  height: 45px;
  padding: 6px 0px;
  box-sizing: border-box;
  text-align: center;
  border: solid 1px #f0f0f5;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: pointer;
}

.users-search-follow-submit-container:hover {
  background-color: #d03e3e;
}

.users-search-unfollow-submit-container {
  height: 45px;
  padding: 6px 0px;
  box-sizing: border-box;
  text-align: center;
  border: solid 1px rgb(223, 223, 232);
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  color: rgb(36, 36, 40);
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: pointer;
  font-weight: 400;
}

.users-search-unfollow-submit-container::Before {
  content: "Following";
}

.users-search-unfollow-submit-container:hover {
  height: 45px;
  padding: 6px 0px;
  box-sizing: border-box;
  text-align: center;
  border: solid 1px rgb(223, 223, 232);
  background-color: #d03e3e;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: pointer;
  font-weight: 400;
  content: "UnFollow";
}

.users-search-unfollow-submit-container:hover::before {
  content: "UnFollow";
}
