.search-container {
  width: 100%;
}

.search-container-form {
  display: flex;
  flex-wrap: nowrap;
}

.search-container-input {
  width: 300px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-style: solid;
  border-right: none;
  border-width: 1px;
  border-color: gray !important;
  padding-left: 5px;
}

.magnifying-css {
  color: white;
  border: 1px solid rgb(0, 0, 0);
  height: 34px;
  width: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  background-color: #ff5353;
}
