.activity-card {
    border-radius: 10px;
    margin: 0 auto 30px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}

.activity-card h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
}

.activity-card p {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.activity-card-owner-container{
    display: grid;
    gap: 10px;
    grid-template-columns: 40px 1fr;
}

.activity-card-owner-container i{
    font-size: 40px;
}

.activity-card-owner-container img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgb(221, 221, 221);
}


.activity-card-type-title-container{
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    margin-top: 5px;
}

.activity-card-title{
    line-height: 28px;
    font-size: 22px;
    font-weight: 700;
}

.activity-card-description-container{
    margin-top: 5px;
    display: grid;
    gap: 10px;
    grid-template-columns: 40px 1fr;
}

.activity-card-stats-headers-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.activity-card-stats-headers-container div {
    font-size: 12px;
    line-height: 16px;
    color: #6d6d78;
}

.activity-card-stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.activity-card-related-info-buttons-container{
    display: flex;
    justify-content: space-between;
}

.activity-card-images-container img{
    height: 200px;
}

.activity-card-owner-image-count{
    display: flex;
    align-items: center;
}

.activity-card-owner-image-count img{
    height: 18px;
}

.activity-card-rating-and-date-container{
    display: flex;
    align-items: center;
}

.activity-card-date{
    color: rgba(110,112,114,1);
}

.activity-card-images-link-container{
    display: flex;
    align-items: center;
}

.activity-card-images-link-container img{
    height: 18px;
}

.activity-card-images-link-text{
    position: relative;
    left: 10px;
}

.activity-card-images-container div{
    border-radius: 10px;
    background-size: cover;
}

.activity-card-images-container {
    display: grid;
    gap: 10px;
}

.activity-card-manage-buttons-container{
    display: flex;
    width: 250px;
    justify-content: space-between;
}

.activity-card-delete-button {
    text-decoration: underline;
    color: rgba(2, 122, 151, 1);
    cursor: pointer;
    width: auto;
}

.activity-card-delete-button-container {
    display: flex;
    align-items: center;
}


.activity-card-edit-button {
    text-decoration: underline;
    color: rgba(2, 122, 151, 1);
    cursor: pointer;
    width: auto;
}

.activity-card-edit-button-container {
    display: flex;
    align-items: center;
}



.activity-card-edit-button-container i {
    font-size: 18px;
    color: rgba(2, 122, 151, 1);
}

.activity-card-delete-button-container i {
    font-size: 18px;
    color: rgba(2, 122, 151, 1);
}

.activity-card-add-photo-button{
    width: 80px;
    padding: 5px;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #666;
    background: #fff;
    border: 1px solid #ccc;
    transition: all 0.6s;
    transition-property: background-color, color, background-position, background-size, border-color, color;
}

.activity-card-add-photo-button:hover{
    cursor: pointer;
    color: rgba(2, 122, 151, 1);
    border: 1px solid rgba(2, 122, 151, 1);
}

.activity-card-photos-buttons-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
}



.kudos-button {
    background-color: #f0f0f5;
    border: none;
    padding: 6px 12px;
    color: #494950;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #f0f0f5;
    transition-property: background-color;
    transition-duration: 1s;
    cursor: pointer;
}
.liked {
    color: #ff5353;
}
