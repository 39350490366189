.users-search-container-background {
  background-color: white;
  min-height: calc((100% - 80px) * .8);
  padding: 20px;
  box-sizing: border-box;
}

.users-search-container {
  margin: auto;
}
