
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #4cd137;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #4cd137;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .log {
    font-size: 32px;
  }


.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 10px;
    margin-left: 10px;
    margin-top: 40px;
  }

  .overlay a {
    padding: 4px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .overlay a:hover,
  .overlay a:focus {
    color: #f1f1f1;
  }

  .overlay .closebtn {
    text-align: right;
    padding-right: 25px;
    font-size: 30px;
  }

  #log-console {
    color: #f1f1f1;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 100px;
    z-index: 5;
  }


body {
    padding: 0;
    margin: 0;
}

html,
body,
#tracker {
    z-index: 0;
}

#tracker {
  height: calc(100% - 60px);
}

.center-buttons {
    text-align: center;
    height: 30px;
    padding-top: 5px;
}

.button {
    display: inline-block;
    width: 100px;
    height: 40px;
    border: gray;
    color: #fff;
    font-weight: bold;
}

.button.start {
    background-color: #4cd137;
}

.button.stop {
    background-color: #e8562a;
}

.button.pause {
    background-color: #ffc312;
}

.message-console {
    position: absolute;
    width: 100%;
    background-color: #535c68;
    color: #fff;
    font-size: 10px;
}

.track-control {
    position: absolute;
    bottom: 40px;
    left: 40px;
}

.log-control {
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.meter-control {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
}

.meter-look {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 15px;
}

.glow {
    font-size: 25px;
    color: #fff;
    text-align: center;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #6ab04c, 0 0 40px #6ab04c, 0 0 50px #6ab04c, 0 0 60px #6ab04c, 0 0 70px #6ab04c;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #badc58, 0 0 40px #badc58, 0 0 50px #badc58, 0 0 60px #badc58, 0 0 70px #badc58, 0 0 80px #badc58;
    }
}
