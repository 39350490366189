.splash-page-wrapper {
  max-width: 1248px;
  margin: auto;
  min-height: calc((100% - 80px) * .8);
}

.splash-page-body {
  display: grid;
  margin: auto;
}

.splash-page-title {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  position: relative;
  top: 25px;
}

.splash-page-activity-select {
  /* width: 97px ; */
  top: 75px;
  height: 40px;
  font-size: 17px;
  left: 385px;
  border: none;
  padding: 5px;
  background-color: #f7f7fa;
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow, background-position, background-size, border-color, color;
  outline-color: #007FB6;
  border-radius: 4px;
}

.splash-page-activity-select:hover {
  background-color: white;
  box-shadow: 2px 2px 10px rgb(191, 191, 191);
}

.splash-page-user-info-container {
  width: 280px;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-page-user-info-icon{
  font-size: 70px;
  height: 70px;
  width: 70px;
  position: absolute;
  top: -35px;
  box-shadow: 0px 0px 15px rgb(194, 194, 194);
  border-radius: 50%;
}

.splash-page-owner-image-button {
  position: absolute;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 0;
  cursor: pointer;
  border: none;
  color: gray;
  left: 155px;
  top: 17px;
  background-color: rgb(227, 227, 227);

}

.splash-page-user-info-stats{
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.splash-page-user-info-stat{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.splash-page-latest-activity-container{
  padding: 10px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.splash-page-latest-activity-container-no-activity{
  padding: 10px;
  font-size: 14px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.splash-page-descriptor{
  color: #6d6d78;
  font-size: 12px;
}

.no-activity-card {
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getting-started-card {
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 4px;
}

.getting-started-card-body {
  padding: 24px;
}
.getting-started-card img {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
}

.getting-started-card-body-container{
  display: grid;
  grid-template-columns: 88px 1fr;
}

.getting-started-connect-device-button{
  padding: 12px 24px;
  box-sizing: border-box;
  text-align: center;
  border: solid 1px #f0f0f5;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: pointer;
}

.getting-started-connect-device-button:hover {
  background-color: #d03e3e;
}
