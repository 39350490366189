.comment-delete-container {
    border-radius: 200px;
    padding: 20px;
    box-sizing: border-box;
}

.comment-delete-form {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.comment-delete-form-title {
    margin: 0;
    display: flex;
    align-items: center;
}
